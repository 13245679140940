import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useState
} from 'react';
import * as S from './LayerContainer.styles';
import { Builder } from '@builder.io/react';
import FunnelContext from '../../../context/FunnelContext';
import useLocalStorage from '../../../hooks/useLocalStorage';

const LayerContainer = props => {
  const {
    layerId,
    children,
    attributes,
    checkQueryParams,
    queryParamString,
    clearAnswers,
    clearExtraObjects,
    removeLocalStorageItems
  } = props;

  const { currentLayer, setCurrentLayer } = useContext(FunnelContext);
  const [layerChecked, setLayerChecked] = useState(false);
  const [answers2, setAnswers2] = useLocalStorage('answers', {});
  const [extraObjects2, setExtraObjects2] = useLocalStorage('extraObjects', {});
  const [, setCurrentLayer2] = useLocalStorage('currentLayer', 'layer-0');

  const clearLS = useCallback(() => {
    if (layerId === currentLayer) {
      if (Object.keys(answers2).length > 0 && clearAnswers) {
        setAnswers2({});
      }
      if (Object.keys(extraObjects2).length > 0 && clearExtraObjects) {
        setExtraObjects2({});
      }
      if (removeLocalStorageItems) {
        removeLocalStorageItems.forEach(({ key }) => {
          localStorage.removeItem(key);
        });
      }
    }
  }, [
    clearAnswers,
    answers2,
    setAnswers2,
    extraObjects2,
    setExtraObjects2,
    clearExtraObjects,
    layerId,
    currentLayer,
    removeLocalStorageItems
  ]);

  useLayoutEffect(() => {
    if (!layerChecked) {
      if (checkQueryParams) {
        const layerKey = queryParamString || 'layer';
        let params = new URLSearchParams(window.location.search);
        const layerParam = params.get(layerKey) ?? 'layer-0';
        setCurrentLayer(layerParam);
        setCurrentLayer2(layerParam);
      }
      setLayerChecked(true);
    } else {
      clearLS();
    }
  }, [
    checkQueryParams,
    queryParamString,
    setCurrentLayer,
    clearLS,
    layerChecked,
    setLayerChecked,
    setCurrentLayer2
  ]);

  const noChildren = () => {
    return <S.NoChildren>insert components here</S.NoChildren>;
  };

  return currentLayer === layerId || Builder.isEditing ? (
    <S.Container {...attributes} layerId={layerId} editing={Builder.isEditing}>
      {children || noChildren()}
    </S.Container>
  ) : null;
};

export default LayerContainer;
